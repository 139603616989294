import { Component, OnInit, OnDestroy, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { SharedService } from './../shared.service';
import { CommonService, LoginDataInterface } from '../common/common.service';
import { HttpClient } from '@angular/common/http';



declare const gtag: any;
declare const menuState: any;
declare let auth2: any;
declare const gapi: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  // navbarOpen = false;
  // toggleNavbar() {
  //   this.navbarOpen = !this.navbarOpen;
  // }
  url;
  iconURl;
  source: string;
  hideFooterEvent: Subscription;
  showFooterEvent: Subscription;
  isLoggedIn = false;
  socialName: string;
  hideFooter = false;
  loginListner: Subscription;
  @Input() routePath: string;

  private auth2: any;
  prodBaseUrl = 'https://leads.backend.upwards.in';

  // downloadAppLink = 'https://play.google.com/store/apps/details?id=com.goupwards';
  downloadAppLink = 'https://play.google.com/store/apps/details?id=com.goupwards';
  // isActive: false;

  constructor(private sharedService: SharedService,
    private commonService: CommonService,
    private http: HttpClient,
    private ngZone: NgZone,
  ) {
    // router.events.subscribe((url: any) => {
    //   console.log(url);
    //   console.log(router.url); 
    //   this.source = router.url;
    // }
  
  }

  //   ToggleMenu() {
  //     const element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
  //     if ( element.getAttribute( 'aria-expanded' ) === 'true' ) {
  //         element.click();
  //     }
  // }

  // toggleMenu() {
  //   this.zone.run(() => {
  //   this.menuState = this.menuState === 'out' ? 'in' : 'out';
  //  });
  // }


  ngOnInit() {

    this.hideFooterEvent = this.sharedService.hideFooter.subscribe(data => {
      this.hideFooter = true;
    });
    this.showFooterEvent = this.sharedService.showFooter.subscribe(data => {
      this.hideFooter = false;
    });
    this.loginListner = this.commonService.loginEvent.subscribe((data: LoginDataInterface) => {
      this.isLoggedIn = true;
      this.socialName = data.first_name + (' ' + data.last_name || '')
    })

    this.url = window.location.pathname;
    if(this.url == "/unnati_data_deletion_req") {
      // this.iconURl = 'assets/img/ic_unnati_logo_1.png';
      this.iconURl = 'assets/img/ic_launcher_fast_loan.png';
    } else if (this.url == "/fastLoan_data_deletion_req") {
      console.log('this is cakkled',this.url, this.iconURl);
      this.iconURl = 'assets/img/ic_unnati_logo_1.png';
      console.log('this is cakkled',this.url, this.iconURl);
    }else {
      this.iconURl = 'https://d5mjn1irzs0nd.cloudfront.net/web/Webp+Assets/logo-black.png'
    }
    
    this.intitiateGoogleApi();
  }
  // triggerGoogleTagEvent() {
  //   gtag('event', 'click', {
  //     'btn-click': ''
  //   });
  // }
  logout(event: any) {
    this.commonService.logoutEvent.emit();
    this.isLoggedIn = false;
    // event.preventDefault();
  }
  ngOnDestroy() {
    this.loginListner.unsubscribe();
  }

  triggerGoogleTagEvent(sendEventAction) {
    this.sharedService.emitEvent("Header", sendEventAction, this.routePath)
  
  }

  intitiateGoogleApi() {
    console.log('google login is clicked')
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '1030522773966-qqqogrml7te8bc1c9a7ka1mdacksaipv.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
        // ux_mode: 'redirect',
      });
    });
  }

  signIn() {
    this.auth2.signIn().then((googleUser: any) => {
      const profile = googleUser.getBasicProfile();
      console.log('ID: ' + profile.getId());
      console.log('Name: ' + profile.getName());
      console.log('Image URL: ' + profile.getImageUrl());
      console.log('Email: ' + profile.getEmail());
      console.log('token: ' + googleUser.getAuthResponse().id_token);
      let googleAuthToken = googleUser.getAuthResponse().id_token;
      this.ngZone.run(() => this.getCustomerSessionToken(googleAuthToken, 'google'));
    });
  }

  attachSignin(element) {
    console.log(element.id);
    auth2.attachClickHandler(element, {},
      function (googleUser) {
        let googleAuthToken = googleUser.getAuthResponse().id_token;
        this.getCustomerSessionToken(googleAuthToken, 'google');
      }, function (error) {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

  getCustomerSessionToken(platformToken: string, platformName: string) {
    console.log('this is called -->>');
    let postData = {
      platform_token: platformToken,
      platform: platformName,
      source: 'web_application_chrome'
    };

    console.log('this is the final output of login -->>', postData);

    this.http.post(this.prodBaseUrl + '/web/v2/customer/social_login/', postData).subscribe((res) => {
      console.log('this is the response ___....', res);
    })
  }

}
