import { Injectable, EventEmitter, RendererFactory2, ViewEncapsulation } from '@angular/core';
// import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router, ActivatedRoute, UrlSegment, RouterStateSnapshot, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';



declare var ga: Function;
declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
    [prop: string]: string;
};




@Injectable({
  providedIn: 'root'
})
export class SharedService {
  hideFooter = new EventEmitter<any>();
  showFooter = new EventEmitter<any>();

  constructor(public router: Router,
              private http: Http,
              private route:ActivatedRoute,
              private rendererFactory: RendererFactory2,) { 
    this.router.events.subscribe(event => {
      try {
        if (typeof ga === 'function') {
          if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            console.log('%%% Google Analytics page view event %%%');
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }
  


  createCanonicalURL() {
  const link: HTMLLinkElement = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  document.head.appendChild(link);
  link.setAttribute('href', document.URL);

  }
  // link.setAttribute('href', this.route.snapshot.url[0].path);

  getArticles(): Observable<Data> {
    return this.http.get( 'https://upwards-assets.s3.ap-south-1.amazonaws.com/web/blog/api/upwards_website_blog_min.json')
    // return this.http.get( 'assets/upwards_website_blog_min.json')
  };


  getNews(): Observable<Data> {
    return this.http.get('https://upwards-assets.s3.ap-south-1.amazonaws.com/backend/static/api/upwards_in_news.json')
  }






 
addTag(tag: LinkDefinition, forceCreation?: boolean) {

  try {
      const renderer = this.rendererFactory.createRenderer(document, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
      });

      const link = renderer.createElement('link');
      const head = document.head;
      const selector = this._parseSelector(tag);

      if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
          return renderer.setAttribute(link, prop, tag[prop]);
      });

      // [TODO]: get them to update the existing one (if it exists) ?
      renderer.appendChild(head, link);

  } catch (e) {
      console.error('Error within linkService : ', e);
  }
}

private _parseSelector(tag: LinkDefinition): string {
  // Possibly re-work this
  const attr: string = tag.rel ? 'rel' : 'hreflang';
  return `${attr}="${tag[attr]}"`;
}


  //  * Emit google analytics event
  //  * Fire event example:
  //  * this.emitEvent("testCategory", "testAction", "testLabel", 10);
  //  * @param {string} eventCategory
  //  * @param {string} eventAction
  //  * @param {string} eventLabel
  //  * @param {number} eventValue
   

public emitEvent(eventCategory: string,
  eventAction: string,
  eventLabel: string = null,
  eventValue: number = null) {
   if (typeof ga === 'function') {
     ga('send', 'event', {
       eventCategory: eventCategory,
       eventLabel: eventLabel,
       eventAction: eventAction,
       eventValue: eventValue
     });
   }
 }

}
