import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { HttpClient } from '@angular/common/http'; 

@Component({
  selector: 'app-upwards-lead-generation-partner',
  templateUrl: './upwards-lead-generation-partner.component.html',
  styleUrls: ['./upwards-lead-generation-partner.component.css']
})
export class UpwardsLeadGenerationPartnerComponent implements OnInit {
  title: 'Upwards - Lead-Generation Partner'
  constructor(private meta: Meta,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private httpClient: HttpClient
    ) { }
    addressMapLink:any;
    LeadsGenerationPartners:any = {};
    leadGenerationpartnerDetails:any = [];
    // LeadsGenerationPartnersUrl = "./assets/lead_generation_partner_data.json"
    LeadsGenerationPartnersUrl = "https://upwards-assets.s3.ap-south-1.amazonaws.com/backend/static/api/lead_generation_partner_data.json";

  ngOnInit() {
    
    this.meta.updateTag({ name: 'description', content: `The company aims to provide the best customer service and is consistently striving to create a robust and efficient customer service platform` });
    this.meta.updateTag({ name: 'author', content: 'Upwards' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.httpClient.get(this.LeadsGenerationPartnersUrl).subscribe(data=>{    
      this.LeadsGenerationPartners = data['leadsGenerationPartners'];
      this.leadGenerationpartnerDetails = this.LeadsGenerationPartners['partnersDetails']
      console.log('this is the list --->>>', this.leadGenerationpartnerDetails);
     });


    
  }

}
