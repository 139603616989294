import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselModule, ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './not-found/not-found.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedService } from './shared.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { LazyLoadingImagesDirective } from './lazy-loading-images.directive';
import { HomeHindiComponent } from './home-hindi/home-hindi.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpInterceptorService } from './http-interceptor.service';
import { PreSignupLeadComponent } from './pre-signup-lead/pre-signup-lead.component';
import { HttpModule } from '@angular/http';
import { DISQUS_SHORTNAME } from 'ngx-disqus';
import { RbiMoratiumComponent } from './rbi-moratium/rbi-moratium.component';
import { CreditLineComponent } from './credit-line/credit-line.component';
import { UpwardsLeadGenerationPartnerComponent } from './upwards-lead-generation-partner/upwards-lead-generation-partner.component';
import { RegulatoryComponent } from './regulatory/regulatory.component';
import { DataDeletionComponent } from './miscellaneous-pages/data-deletion/data-deletion.component';
import { CommonModule } from '@angular/common';

const ngxUILoaderConfig: NgxUiLoaderConfig = {
  "blur": 5,
  "fgsColor": "white",
  "fgsPosition": "center-center",
  "fgsSize": 20,
  "fgsType": "three-bounce",
  "gap": 5,
  "logoPosition": "center-center",
  "logoSize": 60,
  "logoUrl": "assets/img/upwards_logo.png",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  // "text": "“Rule No.1: Never lose money. Rule No.2: Never forget rule No.1.” – Warren Buffett",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
}



// import { DeferLoadModule } from '@trademe/ng-defer-load';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    HomeHindiComponent,
    DataDeletionComponent,
    ComingSoonComponent,
    LazyLoadingImagesDirective,
    PreSignupLeadComponent,
    RbiMoratiumComponent,
    CreditLineComponent,
    UpwardsLeadGenerationPartnerComponent,
    RegulatoryComponent,
    // NoSanitizePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUILoaderConfig),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    // NgxUiLoaderRouterModule,
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    // DeferLoadModule
  ],
  exports: [
    LazyLoadingImagesDirective
  ],
  providers: [Title, SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {provide: DISQUS_SHORTNAME, useValue: 'upwards-quick-personal-loans'}
  ],
  bootstrap: [AppComponent],

})
export class AppModule {


}
