import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulatory',
  templateUrl: './regulatory.component.html',
  styleUrls: ['./regulatory.component.css']
})
export class RegulatoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
