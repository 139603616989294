//import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/mergeMap';

import { Component } from '@angular/core';
import { OnInit} from '@angular/core/src/metadata/lifecycle_hooks';
import { Title } from '@angular/platform-browser';
import { SharedService } from './shared.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, mergeMap, filter } from 'rxjs/operators';




// Title

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{


  public constructor(private titleService: Title, 
                    private sharedService: SharedService,
                    public router: Router,
                    private activatedRoute: ActivatedRoute,
                    
                    
     ) { 
    
  }
  title = 'Upwards-Instant loans at low interest and low EMI';
  ngOnInit() {
    
    this.router.events.pipe
      (filter(event => event instanceof NavigationEnd)
      )
      .pipe(
        map(() => this.activatedRoute), map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }), 
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(event['title']));
  }



}


