import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { SharedService } from './../shared.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';

declare const Email: any;
declare const gtag: any;
declare const branch: any;
interface ReferFriendResponse {
  result: string;
}

@Component({
  selector: 'app-home-hindi',
  templateUrl: './home-hindi.component.html',
  styleUrls: ['./home-hindi.component.css']
})
export class HomeHindiComponent implements OnInit {

  @ViewChild('contactUsForm') contactUsForm: NgForm;
  formSubmitted = false;
  model: any = {};
  loanApplied = true;
  loading = false;
  referReqSubmitted = false;
  submitButtonText = '';
  smsSent = false;

  constructor(private http: HttpClient,
              private location: Location,
              private sharedService: SharedService,
              private route: ActivatedRoute,
              private meta: Meta) { }

  ngOnInit() {

    this.sharedService.addTag( { rel: 'canonical', href: this.route.snapshot.url[0].path} );
    this.sharedService.addTag( { rel: 'alternate', hreflang: 'es', href: 'https://upwards.in/' } );
    this.meta.updateTag({name: 'description', content: 'Upwards Personal Loan - Quick Loan for Salaried Employees'});
    this.meta.updateTag({name: 'author', content: 'Upwards Fintech'});
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }



  sendReferFriendData(formData) {
    // const resource = JSON.stringify(this.contactUsForm.value);
    // console.log('Add Button clicked: ' + resource);
    if (this.referReqSubmitted) {
      alert('You have already submitted the form');
      return;
    }
    this.loading = true;
    /*    setTimeout(() => {
         this.loading = false;
       }, 5000); */

    const postData = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_issue: formData.subject,
      customer_message: formData.msg,
      domain: window.location.href,
      customer_phone_number: formData.phone_number
    };
    this.referReqSubmitted = true;
    const formSubmitUrl = 'https://script.google.com/macros/s/AKfycbx_qm9oWjUiCEM5mPm-m9IS2pNcrncQvy6UQTQna_2wjhhADfRJ/exec';
    // const serializedForm = JSON.stringify(formObj);
    this.http.get<ReferFriendResponse>(formSubmitUrl, { params: postData }).subscribe(response => {
      this.loading = false;
      if (response.result === 'success') {
        this.formSubmitted = true;
      } else {
        this.referReqSubmitted = false;
        alert('Error while submitting form');
      }
      console.log(response);
    }, err => {
      this.loading = false;
      this.referReqSubmitted = false;
      alert('Error while submitting form');
    });

  }
  sendSMS(formData) {
    const phone = '+91' + formData.phone;
    const linkData = {
      tags: [],
      channel: 'Website',
      feature: 'TextMeTheApp',
      data: {
        // foo: 'bar'
      }
    };
    const options = {};
    const callback = (err, result) => {
      if (err) {
        alert('Sorry, something went wrong. Could not send the SMS.');
      } else {
        // alert('SMS sent successfully!');
        this.smsSent = true;
      }
    };
    branch.sendSMS(phone, linkData, options, callback);
    // form.phone.value = '';
  }

  triggerGoogleTagEvent() {
    gtag('event', 'click', {
      'btn-click': 'App Download'
    });
  }
  hideFooter() {
    this.sharedService.hideFooter.emit();
  }
  showFooter() {
    this.sharedService.showFooter.emit();
  }
}
