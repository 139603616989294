import { Component, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { SharedService } from './../shared.service';
import {globalConstants} from './../constants'
declare const Email: any;
declare const gtag: any;
declare const branch: any


interface ReferFriendResponse {
  result: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('contactUsForm') contactUsForm: NgForm;
  formSubmitted = false;
  model: any = {};
  loanApplied = true;
  loading = false;
  // showMyElement = false;
  referReqSubmitted = false;
  submitButtonText = '';
  smsSent = false;
  downloadAppLink = globalConstants.downloadAppLink;
  address: string;
  contactNumber: string;

  constructor(private http: HttpClient,
              private location: Location,
              private sharedService: SharedService) { }

  ngOnInit() {
    this.address = globalConstants.address;
    this.contactNumber = globalConstants.contactNumber

  }
  
  sendReferFriendData(formData) {
    if (this.referReqSubmitted) {
      alert('You have already submitted the form');
      return;
    }
    this.loading = true;
    const postData = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_issue: formData.subject,
      customer_message: formData.msg,
      domain: window.location.href,
      customer_phone_number: formData.phone_number
    };
    this.referReqSubmitted = true;
    // const formSubmitUrl = 'https://script.google.com/macros/s/AKfycbxN7q5Q7gjXuwriHYoFflg6RtqmVQt-6k3rG5HpBfH1aZEDBXyz/exec';
    const formSubmitUrl = 'https://script.google.com/macros/s/AKfycbx_qm9oWjUiCEM5mPm-m9IS2pNcrncQvy6UQTQna_2wjhhADfRJ/exec';
    
    // const serializedForm = JSON.stringify(formObj);
    this.http.get<ReferFriendResponse>(formSubmitUrl, { params: postData }).subscribe(response => {
      this.loading = false;
      if (response.result === 'success') {
        this.formSubmitted = true;
      } else {
        this.referReqSubmitted = false;
        alert('Error while submitting form');
      }
      console.log(response);
    }, err => {
      this.loading = false;
      this.referReqSubmitted = false;
      alert('Error while submitting form');
    });

  }
  sendSMS(formData) {
    const phone = '+91' + formData.phone;
    const linkData = {
      tags: [],
      channel: 'Website',
      feature: 'TextMeTheApp',
      data: {
      }
    };
    const options = {};
    const callback = (err, result) => {
      if (err) {
        alert('Sorry, something went wrong. Could not send the SMS.');
      } else {
        // alert('SMS sent successfully!');
        this.smsSent = true;
      }
    };
    branch.sendSMS(phone, linkData, options, callback);
    // form.phone.value = '';
  }

 
  formSubmitGoogleEvent() {
    this.sharedService.emitEvent("Contact Us Form", "Submit Button Click", "home")
  }

  triggerGoogleTagEvent(sendEventAction) {
    this.sharedService.emitEvent("Internal Links", sendEventAction, "home")
  
  }



  hideFooter() {
    this.sharedService.hideFooter.emit();
  }
  showFooter() {
    this.sharedService.showFooter.emit();
  }
}

