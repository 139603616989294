import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rbi-moratium',
  templateUrl: './rbi-moratium.component.html',
  styleUrls: ['./rbi-moratium.component.css']
})
export class RbiMoratiumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
