import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeHindiComponent } from './home-hindi/home-hindi.component';
import { PreSignupLeadComponent } from './pre-signup-lead/pre-signup-lead.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { RbiMoratiumComponent } from './rbi-moratium/rbi-moratium.component';
import { CreditLineComponent } from './credit-line/credit-line.component';
import { UpwardsLeadGenerationPartnerComponent } from './upwards-lead-generation-partner/upwards-lead-generation-partner.component';
import { RegulatoryComponent } from './regulatory/regulatory.component';
import { FaqsComponent } from './miscellaneous-pages/faqs/faqs.component';
import { DataDeletionComponent } from './miscellaneous-pages/data-deletion/data-deletion.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // redirectTo: '',
    pathMatch: 'full',
    data: { title: 'Upwards-Personal loans at low interest and low EMI' }
  },
  {
    path: 'upwards-lead-generation-partners',
    component: UpwardsLeadGenerationPartnerComponent,
    data: {title: 'Upwards Lead Generation Partners'}
  },
  {
    path: 'regulatory',
    component: RegulatoryComponent,
    data: {title: 'Regulatory'}
  },
  {
    path: 'apply-for-credit-line-card-online-loan',
    component: CreditLineComponent,
    // redirectTo: '',
    pathMatch: 'full',
    data: { title: 'Upwards Credit Line: Apply for Credit Line Card Online in India' }
  },
  {
    path: 'hindi',
    component: HomeHindiComponent,
    data: { title: 'अप्वर्ड्स- इन्स्टेंट लोन कम इंटेरेस्ट रेट और लो ईएमआई पर' }
  },
  {
    path: 'rbi-moratorium-on-loan-repayment',
    component: RbiMoratiumComponent,
    data: {title: `RBI's Moratorium on Loan Repayment in wake of Covid-19`}

  },
  {
    path: 'funcenter',
    loadChildren: './funcenter/funcenter.module#FuncenterModule'
  },
  {
    path: '',
    loadChildren: './company/company.module#CompanyModule'
  },
  {
    path: '',
    loadChildren: './personal-loan-city/personal-loan-city.module#PersonalLoanCityModule'
  },
  {
    path: '',
    loadChildren: './instant-loan-city/instant-loan-city.module#InstantLoanCityModule'
  },
  {
    path: '',
    loadChildren: './personal-loan-situations/personal-loan-situations.module#PersonalLoanSituationsModule'
  },
  {
    path: 'blog',
    loadChildren: './blog/blog.module#BlogModule'
  },
  {
    path: 'help',
    loadChildren: './help-center/help-center.module#HelpCenterModule'
  },
  {
    path: '',
    loadChildren: './miscellaneous-pages/miscellaneous-pages.module#MiscellaneousPagesModule'
  },
  {
    path: '',
    loadChildren: './seo-pages/seo-pages.module#SeoPagesModule'
  },

  {path: '',
  loadChildren: './new-campaign/new-campaign.module#NewCampaignModule'

  },
  // {
  //   path: 'data_deletion_req',
  //   loadChildren: () => new Promise( () => { if(window.location.href.match(/data_deletion_req/) ) window.location.href = 'https://play.google.com/store/apps/details?id=com.goupwards&pcampaignid=web_share'; } ) 
  // },

  {
    path: 'data_deletion_req',
    component: DataDeletionComponent,
    // loadChildren: () => new Promise( () => { if(window.location.href.match(/data_deletion_req/) ) window.location.href = 'https://upwards.in/faqs?utm_source=upwards-lead-generation-partners&utm_medium=footer_link'; } )
  },
  {
    path: 'unnati_data_deletion_req',
    component: DataDeletionComponent,
    // loadChildren: () => new Promise( () => { if(window.location.href.match(/data_deletion_req/) ) window.location.href = 'https://upwards.in/faqs?utm_source=upwards-lead-generation-partners&utm_medium=footer_link'; } )
  },
  {
    path: 'fast_loan_data_deletion_req',
    component: DataDeletionComponent,
    // loadChildren: () => new Promise( () => { if(window.location.href.match(/data_deletion_req/) ) window.location.href = 'https://upwards.in/faqs?utm_source=upwards-lead-generation-partners&utm_medium=footer_link'; } )
  },
  {
    path: 'gamezone',
    loadChildren: './upwards-gamezone/upwards-gamezone.module#UpwardsGamezoneModule'
  },

  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'instant-loan',
    component: PreSignupLeadComponent,
    data: {title: 'Get Instant Loan from Upwards'}
  },

  {
    path: 'emails/category/campaigns/unsubscribe/forms',
    component: ComingSoonComponent
  }, 

  {
    path: 'games/puzzle',
    component: ComingSoonComponent
  },

  {
    path: 'games/trivia',
    component: ComingSoonComponent
  },

  {
    path: '**',
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
exports: [RouterModule]
})
export class AppRoutingModule { }
