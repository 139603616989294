import { Component, OnInit, Input } from '@angular/core';
import { SharedService} from './../shared.service';
import {globalConstants} from './../constants'


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  source: string;
  @Input() routePath: string;
version = globalConstants.version;
downloadAppLink = globalConstants.downloadAppLink;

  constructor(private sharedService: SharedService,) 
              {
                /* router.events.subscribe((url: any) => {
                  console.log(url);
                  // console.log(router.url); 
                  this.source = router.url;

            
                }) */
               }

  ngOnInit() {
  }
  hideFooter() {
    this.sharedService.hideFooter.emit();
  }

  triggerGoogleTagEvent(sendEventAction) {
    this.sharedService.emitEvent("Footer", sendEventAction, this.routePath)
  
  }
}
