import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-faqs',
  templateUrl: './data-deletion.component.html',
  styleUrls: ['./data-deletion.component.css']
})
export class DataDeletionComponent implements OnInit {
  // title = 'Upwards | Frequently Asked Questions';

  url;
  appName;

  constructor(private shareService: SharedService,
              private route: ActivatedRoute,
              private meta: Meta

  ) { }

  ngOnInit() {

    //data_deletion_req
    //unnati_data_deletion_req
    //fastLoan_data_deletion_req
    console.log('this is the url',window.location.pathname);
    this.url = window.location.pathname;
    if(this.url == "/unnati_data_deletion_req") {
      this.appName = 'Unnati';
    } else if (this.url == "/fast_loan_data_deletion_req") {
      this.appName = 'Fastest Online Loan(Upto Rs 1L)';
    }else {
      this.appName = 'Upwards'
    }

    this.shareService.addTag( { rel: 'canonical', href: this.route.snapshot.url[0].path} );
    this.meta.updateTag({name: 'description', content: 'Ask Common questions already answered by us'});
    this.meta.updateTag({name: 'author', content: 'Upwards Fintech'});
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }

}
