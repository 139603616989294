import { Injectable, EventEmitter } from '@angular/core';
export interface LoginDataInterface {
  first_name: string;
  last_name: string;
  email_id: string;
  profile_pic_link?: string;
  session_token: string;
  customer_id: number;
  loan_id: number;
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  displayData: object;
  logoutEvent = new EventEmitter();
  loginEvent = new EventEmitter<LoginDataInterface>();

  constructor() { }
  setDisplayData(data: object) {
    this.displayData = data;
  }
  /* showLoader() {
    this.loaderEmit.emit({show: true});
  }
  hideLoader() {
    this.loaderEmit.emit({show: false});
  } */

}
