import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pre-signup-lead',
  templateUrl: './pre-signup-lead.component.html',
  styleUrls: ['./pre-signup-lead.component.css']
})
export class PreSignupLeadComponent implements OnInit {
  loanForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl(''),
    social_email_id: new FormControl('', [Validators.required, Validators.pattern(/([a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256})(\@)([a-zA-Z0-9][a-zA-Z0-9\\-]{0,64})(\.)([a-zA-Z0-9][a-zA-Z0-9\\-]{1,25})/)]),
    mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    company: new FormControl('', Validators.required),
    salary: new FormControl('', Validators.required),
    pincode: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{5}$/)]),
    pan: new FormControl('', Validators.pattern(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/))
  });
  loanAmount: number;
  loanTenure: number;
  loanEmi: number;
  interestRate: number;
  isApproved: boolean;
  isFormSubmitted: boolean;
  originId = 1002;
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  prodBaseUrl = 'https://backend.upwards.in';

  // prodBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:8080';
  // prodBaseUrl = 'http://ec2-13-126-207-236.ap-south-1.compute.amazonaws.com:8080';

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private meta: Meta
    ) {
    this.route.queryParamMap.subscribe(queryParamsMap => {
      this.utmSource = queryParamsMap.get('utm_source');
      this.utmCampaign = queryParamsMap.get('utm_campaign');
      this.utmMedium = queryParamsMap.get('utm_medium')
    })
  }

  ngOnInit() {
    this.meta.updateTag({name: 'description', content: 'Apply for a instant loan today!'});
    this.meta.updateTag({name: 'author', content: 'Upwards Fintech'});
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }
  applyInstantLoan() {
    this.isFormSubmitted = true;
    const postData = {
      first_name: this.first_name.value,
      last_name: this.last_name.value,
      social_email_id: this.social_email_id.value,
      mobile_number: this.mobile_number.value,
      company: this.company.value,
      salary: this.salary.value,
      pincode: this.pincode.value,
      pan: this.pan.value ? this.pan.value.toUpperCase() : undefined,
      origin_id: this.originId,
      source: this.utmSource || 'organic',
      medium: this.utmMedium || 'organic',
      campaign: this.utmCampaign || 'organic',
    };
    this.http.post(this.prodBaseUrl + '/web/v1/pre_signup/lead/', postData).subscribe((res) => {
      if (res && res['data']) {
        const aipData = res['data']
        if (aipData['status'] === 'Approved') {
          this.loanAmount = aipData['amount'];
          this.loanEmi = aipData['emi'];
          this.loanTenure = aipData['tenure'];
          this.interestRate = Math.round(aipData['flat_interest_rate_per_tenure'] * 100 * 100) / 100;
          this.isApproved = true;
        } else if (aipData['status'] === 'Rejected') {
          this.isApproved = false
        }
      }
    })
  }
  get first_name() { return this.loanForm.get('first_name') }
  get last_name() { return this.loanForm.get('last_name') }
  get social_email_id() { return this.loanForm.get('social_email_id') }
  get pan() { return this.loanForm.get('pan') }
  get mobile_number() { return this.loanForm.get('mobile_number') }
  get company() { return this.loanForm.get('company') }
  get pincode() { return this.loanForm.get('pincode') }
  get salary() { return this.loanForm.get('salary') }
}
